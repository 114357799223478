<template>
  <div id="nextRank">
      <div class="header">
        <div class="left">
          <!-- <p class="title">智能模板</p> -->
          <el-button
            type="primary"
            size="medium"
            @click="newdata"
          >
            添加直播
          </el-button>
        </div>
        <div class="right">
          <el-select
            style="width: 140px"
            v-model="post.live_mode"
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
              v-model="post.search"
              size="medium"
              placeholder="输入直播名称搜索"
              style="width: 218px"
          ></el-input>
        </div>
      </div>
      <div class="paginationBox">
        <pagination2 :option="post" url="/liveGroup/detailsList" ref="childDialogBox" class="mt20 childDialogBox flex1"
          @complete="complete">
          <template v-slot:default="{ tableData }">
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
            >
              <el-table-column label="直播名称" prop="name" width="400">
                <template slot-scope="{ row }">
                  <div class="course-info cp" @click="open(row)">
                    <div class="cover" v-if="row.cover_img">
                      <lazy class="img" :src="row.cover_img"></lazy>
                    </div>
                    <div class="right">
                      <div class="title">{{ row.name }}</div>
                      <div class="price">
                        <span class="redcolor" v-if="row.play_type != 2">
                          <span v-if="row.play_price > 0">
                            ￥{{ row.play_price }}
                          </span>
                          <span v-else>免费</span>
                        </span>
                        <span class="redcolor" v-else>
                          密码：{{ row.play_pwd }}
                        </span>

                        <span
                          class="strick"
                          v-if="row.play_type == 3 && row.cost_price > 0"
                        >
                          ￥{{ row.cost_price }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="直播类型" prop="name">
                <template slot-scope="scope">
                    {{ scope.row.live_mode| getLiveMode }}
                  </template>
              </el-table-column>
              <el-table-column label="添加时间" prop="uname" width="180">
                <template slot-scope="scope">
                    {{ scope.row.c_time| formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </template>
              </el-table-column>
              <el-table-column label="排序" prop="sort" width="180">
                <template slot-scope="{row}">
                  <el-popover
                    placement="right"
                    :ref="`popover2${row.id}`"
                    class="aa"
                    width="410"
                    trigger="click"
                  >
                    <div class="contain">
                      排列至第
                      <el-input
                        v-model="rankNumber"
                        class="ml10 mr10"
                        style="width:100px"
                        placeholder="输入数字"
                        @keyup.enter.native="setSort(row)"
                        size="medium"
                        onkeyup="value = value.replace(/[^\d]/g, '')"
                      ></el-input>
                      位
                      <el-button
                        type="primary"
                        size="medium"
                        class="ml10 mr10"
                        @click="setSort(row)"
                      >
                        确定
                      </el-button>
                      <el-button
                        type=""
                        size="medium"
                        @click="cancel1()"
                      >
                        取消
                      </el-button>
                    </div>
                    <el-button slot="reference" type="text">
                      {{ row.sort }}<i class="el-icon-edit"></i>
                    </el-button>
                  </el-popover>
                  </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="180">
                <template slot-scope="{ row }">
                  <el-button type="text" @click="changeGroup(row)">
                    修改分组
                  </el-button>
                  <el-button type="text" @click="removeGroup(row)">
                    移出分组
                  </el-button>
                  <el-button type="text" @click="share(row)">
                    分享
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
      <!-- 推广 -->
      <share
        v-if="showShare"
        :selectrow="selectrow"
        :dialogstatus.sync="showShare"
      ></share>
      <!-- 公开课 -->
      <networkschool-select
        @selectChangenetworkschool="closeFn"
        @singleselectcontentval="saveFn"
        title="选择公开课"
        :singleselect="false"
        :goodList="courseList"
        :dialogstatus.sync="networkschoolSelectStatus"
        :template="24"
        :id_type="6"
        :group_id="post.group_id"
        :group_name="group_name"
        ></networkschool-select>
      <!-- 分组 -->
      <CourseConfirm
        v-if="showGroup"
        title="修改分组"
        :isShowBtn="true"
        @cancelFn="closeFn2"
        @save="saveFn2"
      >
        <div class="group mr20 ml20">
          <el-form label-width="80px">
            <el-form-item label="当前分组">{{ group_name }}</el-form-item>
            <el-form-item label="选择分组">
              <YSelect
                v-model="groupInfo.group_id" 
                :hasAll="false"
                :options="options2" 
                :loading="loading" 
                :placeholder="'请选择xxx'" 
                @visibleChange="visibleChange" 
                @loadMore="loadMore" 
                @remoteMethod="remoteMethod" 
                @change="change"
              ></YSelect>
            </el-form-item>
          </el-form>
        </div>
      </CourseConfirm>
      <!-- 分享 -->
      <shareinfo
        v-if="shareinfo"
        :dialogstatus.sync="shareinfo"
        :shareinfoval="shareinfoval"
        fatherPath="/publicClass"
      ></shareinfo>
  </div>
</template>

<script>
import share from './components/share'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import bg from '@/mixin/background'
import CourseConfirm from '@/components/CourseConfirm'
import shareinfo from '@/components/openCourse/shareinfo'

export default {
  name:'nextRank',
  mixins: [bg],
  data(){
    return {
      post:{
        search:'',
        sort:'',
        live_mode:0,
      },
      iscomplete: false,
      tableList:[],
      // 分享
      showShare:false,
      selectrow: {},
      // 课程
      courseList:[],
      networkschoolSelectStatus:false,
      // 分组
      showGroup:false,
      groupInfo:{},
      // 分享
      shareinfoval: {}, //分享直播的数据
      shareinfo: false,

      rankNumber: '',

      options: [
        {
          value: 0,
          label: '全部直播类型',
        },
        {
          value: 1,
          label: '标准直播',
        },
        {
          value: 2,
          label: '快直播',
        },
        {
          value: 3,
          label: '智能直播',
        },
      ],
      options2: [],

      selectForm: {
        page: 1,
        num: 15,
        pagesize: 15,
        search: ''
      },
      loading: false
    }
  },
  components: {
    share,
    networkschoolSelect,
    CourseConfirm,
    shareinfo,
  },
  filters:{
    getLiveMode(e){
      let str = ''
      switch (Number(e)) {
        case 0:
          str='全部'
          break;
        case 1:
          str='标准直播'
          break;
        case 2:
          str='快直播'
          break;
        case 3:
          str='智能直播'
          break;
      }
      return str
    },
  },
  created(){
    this.post.group_id = this.$route.query.group_id
    this.group_name = this.$route.query.group_name
  },
  methods:{
    newdata(){
      this.networkschoolSelectStatus = true
    },
    closeFn(){
      this.networkschoolSelectStatus=false
    },
    saveFn(val){
      this.$http({
        url: '/liveGroup/addDetails',
        data: {
          group_id:this.$route.query.group_id,
          open_class_ids:val.map(v=>v.open_class_id)
        },
        callback: () => {
          this.closeFn()
          this.$root.prompt({
            msg: '添加成功',
            type: 'success',
          })
          this.$refs.childDialogBox.reset()
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    groupList(){},
    changeGroup(row){
      this.showGroup=true
      this.options2=[]
      this.groupInfo = {
        id:row.id,
        group_id:this.$route.query.group_id,
      }
      this.init()
    },
    removeGroup(row){
      this.$confirm('确定移出分组', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/liveGroup/delDetails',
            data: { id: row.id },
            successMsg: true,
          })
          this.$root.prompt({
            msg: '移出成功',
            type: 'success',
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },
    //分享直播
    share(item) {
      this.shareinfoval = item
      this.shareinfo = true
    },
    // 排序
    async setSort(item) {
      if (!this.rankNumber) {
        this.$root.prompt('请输入序号！')
        return
      }
      await this.$http({
        url: '/liveGroup/setSort',
        data: {
          id: item.id,
          sort: this.rankNumber,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
      this.cancel1()
    },
    // 第一级排序取消按钮
    cancel1() {
      document.body.click()
      this.rankNumber = ''
    },
    // 获取列表数据
    complete(val) {
      this.tableList = val.data
      this.iscomplete = true
    },

    closeFn2(){
      this.showGroup=false
      this.groupInfo={}
    },
    saveFn2(){
      this.$http({
        url: '/liveGroup/moveDetailsGroup',
        data: {
          id:this.groupInfo.id,
          group_id:this.groupInfo.group_id,
        },
        callback: () => {
          this.closeFn2()
          this.$root.prompt({
            msg: '修改成功',
            type: 'success',
          })
          this.$refs.childDialogBox.reset()
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },

    // 打开外部链接
    open(row) {
      // this.$store.dispatch('open', url)
      if(row.live_mode==3){
        this.$router.push({ path: '/intelligence/detail/' + row.open_class_id + '/' + 1 })
      } else {
        this.$router.push({ path: '/publicClass/detail/' + row.open_class_id + '/' + 1 })
      }
    },
    
    async init() {
      this.loading = true
      await this.$http({
        url: '/liveGroup/getGroupList',
        data: this.selectForm,
        callback: ({data}) => {
          const list = data.map(v=>Object.assign(v,{
            id:v.group_id,
            name:v.group_name,
          }))
          this.options2 = _.merge(this.options2,list)
          console.log(this.options2);
          this.loading = false
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    change(value) {
      console.log(value);
    },
    visibleChange(status) {
      if(!status){
        this.selectForm.search = ''
        // this.options2=[]
      } else {
        this.init()
      }
    },
    loadMore(page) {
      this.selectForm.page = page
      this.init()
    },
    remoteMethod(query, page) {
      this.selectForm.page = page
      this.selectForm.search = query
      this.options2=[]
      this.init()
    },
  },
}
</script>

<style scoped lang="scss">

#LiveGrouping {
  margin: 20px;
  padding: 20px;
  background: #fff;
  min-width: 868px;
  .header {
    font-size: 13px;
    // padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .paginationBox {
  }
  .course-info {
    display: flex;
    .cover {
      width: 68px;
      height: 38px;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      margin-left: 8px;
      .title {
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        color: rgba(51, 51, 51, 1);
      }
      .price {
        margin-top: 6px;

        .redcolor {
          font-size: 12px;
          font-weight: bold;
          line-height: 14px;
        }
        .strick {
          margin-left: 6px;
          text-decoration: line-through;
          font-size: 12px;
          color: #666666;
          line-height: 14px;
        }
      }
    }
  }
}
</style>